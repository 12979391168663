import { useSwitchNetwork } from 'wagmi'
import CustomRKButton from '../customRKButton'
import { Puff } from 'react-loading-icons'

export default function UserWarning({ type }) {
  const network = useSwitchNetwork({
    chainId: 1,
  })

  return (
    <>
      {type === 'notAuthorized' ? (
        <div className='userWarningContainer'>
          <div className='title'>
            <img src='/logo.png' alt='FirstBatch Logo' />
            <span>FirstBatch</span>
          </div>
          <div className='row'>
            <div className='big'>SOLANA</div>
            <div className='smol'>breakpoint</div>
          </div>
          <div className='text'>Welcome! Connect to continue</div>
          <CustomRKButton />
        </div>
      ) : type === 'wrongChain' ? (
        <>
          <div className='userWarningContainer'>
            <div className='title'>
              <img src='/logo.png' alt='FirstBatch Logo' />
              <span>FirstBatch</span>
            </div>
            <div className='text'>
              Connected to wrong chain, please switch to Polygon Mumbai.
            </div>
            <button onClick={() => network.switchNetwork()}>
              Switch Network
            </button>
          </div>
        </>
      ) : type === 'usedAcc' ? (
        <div className='userWarningContainer'>
          <div className='title'>
            <img src='/logo.png' alt='FirstBatch Logo' />
            <span>FirstBatch</span>
          </div>
          <div className='text'>Account you tried to connect is used.</div>
        </div>
      ) : type === 'taskIdPending' ? (
        <div className='userWarningContainer'>
          <Puff />
        </div>
      ) : type === 'init' ? (
        <div className='userWarningContainer'>
          <div className='title'>
            <img src='/logo.png' alt='FirstBatch Logo' />
            <span>FirstBatch</span>
          </div>
          <div className='text'>Initializing</div>
          <Puff />
        </div>
      ) : (
        type === 'wrongConnector' && (
          <div className='userWarningContainer'>
            <div className='title'>
              <img src='/logo.png' alt='FirstBatch Logo' />
              <span>FirstBatch</span>
            </div>
            <div className='text'>Please use the same account next time.</div>
          </div>
        )
      )}
    </>
  )
}
